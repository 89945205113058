import React from 'react';
import data from '../../data/company-list.json';

export default function CompanyList() {

  return (
    <div className="splitted-section company">
      {Object.keys(data).map((key) => (
        <div key={key} className="splitted-section--third pr-5">
          {data[key].map((item, index) => (
            <div key={index} className="margin-bottom-half">
              <span className='company__name'><strong>{item.title}</strong></span> <br />
              {item.internal === "true" ?
                <span className='company__link'><a href={item.href} target='_blank'>https://intermediaries.hsbc.co.uk{item.href}</a></span>
                :
                <span className='company__link'><a href={item.href} target='_blank'>{item.href}</a></span>}
              {/* <span className='company__link'><a href={item.href} target='_blank'>{item.href}</a></span> */}
              <span className='company__link'>{item.text}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}