import React, { useEffect } from "react";
import Layout from "../components/layout";
import FAQPanel from "../components/faq-panel/faq-panel.component";
import "../styles/app.scss";
import CompanyList from "../components/complaints/company-listing.component";

export default function ComplaintsPage() {
  useEffect(() => {
    if (typeof window !== undefined) {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <Layout title="Complaints">
      <section className=" row section complaints">
        <div className="col-xs-12 col-lg-8">
          <div>
            <h1 className="page-title">Complaints</h1>
          </div>
          <div className="complaints__body">
            <h3 className="strong">
              If your complaint is regarding your Mortgage Broker, please select the firm from the below list for their
              complaints process:
            </h3>
            <CompanyList />
            <h3 className="strong">If your broker firm is not listed:</h3>
            <p className="complaints__title">
              Please contact your broker directly by phone, online or your complaints procedure may be in your Terms of
              Business document.
            </p>
            <h3 className="strong">If your complaint is regarding your HSBC UK Mortgage:</h3>
            <p className="complaints__title">
              Full details of our customer complaints procedures can be found{" "}
              <a className="anchor-blue" href="https://www.hsbc.co.uk/help/feedback-and-complaints/" target="_blanc">
                here
              </a>
              .
            </p>
            <br />
            <br />
            <br />
          </div>
        </div>
        <div className="col-xs-12 col-lg-4">
          <FAQPanel />
        </div>
      </section>
    </Layout>
  );
}
